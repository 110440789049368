@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Share Tech Mono', monospace !important;
  height: 100%;
  background: rgb(41, 41, 41) !important;
  color: white !important;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Share Tech Mono', monospace !important;
}
