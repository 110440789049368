@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.App {
  height: 100% !important;
}

.typewriter-wrapper {
  height: 100vh;
  /*This part is important for centering*/
  display: flex;
  justify-content: center;
}

.bash-icon {
  position: absolutes;
  justify-content: center;
  left: 1px;
  top: 20px;
}

.typewriter {
  margin: auto;
  animation: typing 2s steps(18), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2.5em;
  width: 19.3ch;
  font-family: 'VT323', monospace;
}

@keyframes typing {
  from {
    width: 0
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}

#welcome {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-size: 22px;
}

#about {
  height: 100vh !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-main {
  width: 450px;
  margin: auto;
}

.title {
  margin-top: -1em;
}

.description {
  margin-top: 12px;
}

.navbar {
  background: rgb(41, 41, 41) !important;
}

.btn {
  height: 3em;
  border: none;
  border-radius: 5px !important;
}

.github-icon {
  margin-right: 7px;
  height: 2em !important;
  
}

#projects {
  height: 100vh !important;
  width: 500px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#contact {
  width: 350px;
  overflow: visible;
  margin: auto;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

a {
  color: pink;
}

a:visited {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}

a:link {
  color: pink;
}

a:hover {
  color: red;
  background-color: transparent;
}

a:active {
  color: pink;
  background-color: transparent;
}